<script src="https://cdnjs.cloudflare.com/ajax/libs/vue/2.5.13/vue.js"></script>
<script>
import BaseButton from '@/shared/components/BaseButton.vue';
import { server } from '@/const';
import { destroyToken, getToken } from '@/shared/services/jwt.service';
import Notify from '@/shared/utils/notify';
import MentorsModel from '@/modules/mentors/mentors-model';
import AddMentor from '@/shared/components/AddMentor.vue';
import i18n from "../../../shared/plugins/vue-i18n";

const { fields } = MentorsModel;
const store = {
  state: {
    mentors: [],
    mentorId: 0,
  },
};

export default {
  components: {
    BaseButton,
    AddMentor,
  },
  data() {
    return {
      store,
      fields,
      model: {},
    };
  },
  methods: {
    openAddMentor() {
      const addMentorWindow = document.querySelector('.add-item');
      addMentorWindow.classList.toggle('show');
      if (addMentorWindow.classList.value.includes('hide')) {
        addMentorWindow.classList.toggle('hide');
      } else {
        setTimeout(() => {
          addMentorWindow.classList.toggle('hide');
        }, 300);
      }
    },
    searchMentors() {
      store.state.mentors = [];
      const listMentorsPath = '/WebListEmployee.php';
      const data = new FormData();
      data.append('role', 'manager');
      data.append('limit', 100);
      data.append('offset', 0);
      data.append('name', this.model.search?? '');

      fetch(server + listMentorsPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 'error') {
            if (json.error.includes('Wrong token')) {
              this.$router.push({ name: 'login' });
              destroyToken();
            } else {
              Notify('warning', json.error);
            }
          }
          if (json.users) {
            json.users.forEach((item) => {
              store.state.mentors.push({
                accepted: item.accepted,
                date_invited: item.date_invited,
                id: item.id,
                image: item.image,
                name: item.name,
                email: item.email,
              });
            });
          }
        });
    },
    resendInvitation(id) {
      const resendInvitationPath = '/WebResendInvitation.php';
      const data = new FormData();
      data.append('id', id);

      fetch(server + resendInvitationPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 'error') {
            if (json.error.includes('Wrong token')) {
              this.$router.push({ name: 'login' });
              destroyToken();
            } else {
              Notify('warning', json.error);
            }
          }
          if (json.status === 'ok') {
            Notify('success', i18n.t('GENERAL.SAVED_SUCCESSFULLY'));
          }
        });
    },
    editMentor(id) {
      setTimeout(() => {
        store.state.mentorId = id ? id : 0;
      }, 300);
      this.openAddMentor();
    },
  },
  beforeMount() {
    store.state.mentors = [];
    this.searchMentors();
  },
};

</script>
<template lang="html">
  <div>
    <div class="d-inline-flex" style="margin-bottom: 24px">
      <div class="title-0-medium-28 basic-1" style="margin-right: 40px">
        {{ $t("EMPLOYEE.EMPLOYEE") }}
      </div>
      <div class="basic-3 subhead-reg-14" style="margin: 6px 0;display: flex;align-items: center">
        <unicon name="estate" width="16" height="16" fill="#1694D0"></unicon>
        <unicon name="angle-right" width="20" height="20" fill="#828282"/>
        <div class="d-inline">
          {{ $t("EMPLOYEE.EMPLOYEE") }}
        </div>
      </div>

    </div>
    <div>
      <div class="mentors-details-header">
        <div class="search-container">
          <div class="custom-input">
            <input type="text"
                   :id="fields.search.name"
                   v-model="model[fields.search.name]"
                   :placeholder="fields.search.label"
                   style="padding-left: 40px"/>
            <unicon fill="#828282" height="16" width="16" name="search"
                    style="cursor: pointer" v-on:click="searchMentors"/>
          </div>
        </div>
        <div class="add-mentor" v-on:click="editMentor(0);">
          <BaseButton>
            <unicon name="plus" width="16" height="16"
                    style="margin-right: 12px;margin-top: -2px" fill="white"/>
            {{ $t('EMPLOYEE.ADD_EMPLOYEE') }}
          </BaseButton>
        </div>
      </div>
    </div>
    <b-table-simple class="custom-table" v-if="store.state.mentors.length > 0">
      <b-thead>
        <b-tr>
          <b-th style="width: 5%;text-align: left">
            {{ $t('EMPLOYEE.NO') }}
          </b-th>
          <b-th style="width: 20%;text-align: left">
            {{ $t('EMPLOYEE.NAME') }}
          </b-th>
          <b-th style="width: 25%;text-align: center">
            {{ $t('EMPLOYEE.EMAIL') }}
          </b-th>
          <b-th style="width: 15%;text-align: center">
            {{ $t('EMPLOYEE.DATE') }}
          </b-th>
          <b-th style="width: 17%;text-align: center">
            {{ $t('EMPLOYEE.STATUS') }}
          </b-th>
          <b-th style="width: 11%;text-align: center">
            {{ $t('EMPLOYEE.CHAT') }}
          </b-th>
          <b-th style="width: 7%;text-align: center">
            {{ $t('EMPLOYEE.MORE') }}
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="(item,i) in store.state.mentors" :key="i" :class="i % 2 == 0 ? 'even' : 'odd'"
              style="cursor: pointer">
          <b-td class="item-no subhead-reg-14 basic-1" style="width: 5%;"
                @click="$router.push({ name: 'mentor_details' ,params: { id: item.id }})">
            {{ i + 1 }}
          </b-td>
          <b-td class="mentor-image headline-medium-16 default-color"
                @click="$router.push({ name: 'mentor_details' ,params: { id: item.id }})"
                style="text-align: left;width: 20%">

            <div class="user-details">
              <div class="d-inline-flex">
                <div class="user-image">
                  <img :src="item.image ? item.image : '/media/user.jpg'" alt=""/>
                </div>
                <div class="user-info" style="display: inline-flex;align-items: center">
                    <div style="display:flex;margin-bottom: 3px">
                      <div class="headline-bold-16 basic-1 mb-0">
                        {{ item.name }}
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </b-td>
          <b-td  style="text-align: center;vertical-align: middle;
          width: 25%" class="headline-regular-16 basic-1"
                 @click="$router.push({ name: 'mentor_details' ,params: { id: item.id }})">
            {{ item.email }}
          </b-td>
          <b-td  style="text-align: center;vertical-align: middle;
          width: 15%" class="headline-regular-16 basic-1"
                 @click="$router.push({ name: 'mentor_details' ,params: { id: item.id }})">
            {{ item.date_invited }}
          </b-td>
          <b-td style="justify-content: center;;vertical-align: middle;width: 17%" class="status"
                @click="$router.push({ name: 'mentor_details' ,params: { id: item.id }})">
            <div class="success" v-if="item.accepted">
              {{ $t('EMPLOYEE.ACTIVE') }}
            </div>
            <div class="danger" v-if="item.accepted === false">
              {{ $t('EMPLOYEE.INACTIVE') }}
            </div>
          </b-td>
          <b-td style="width: 12%;vertical-align: middle;">
<!--            <button :class="item.accepted ? 'btn-light' : 'btn-inactive'"
               style="display:flex!important;margin: auto;width: fit-content">

              <unicon :fill="item.accepted ? '#1694D0' : '#BDBDBD'"
                      height="16" width="16" name="message" style="cursor: pointer;
                      margin-right: 12px"/>
              {{ $t('EMPLOYEE.CHAT') }}
            </button>-->
          </b-td>
          <b-td style="justify-content: center;vertical-align: middle;width: 6%">
            <div class="action" style="cursor: pointer;"
                 v-on:click="editMentor(item.id);">
              <unicon name="ellipsis-v" fill="#333333"
                      height="16" width="16"/>
            </div>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <div class="add-item hide">
      <AddMentor :key="store.state.mentorId" :mentor-id="store.state.mentorId"
                 :callback="searchMentors"
      >
      </AddMentor>
    </div>
  </div>

</template>

<style lang="scss">
.mentors-details-header {
  padding: 8px;
  margin: 1px 0;
  background: #FFFFFF;
  border-radius: 12px 12px 0 0;
  overflow: hidden;
}
.mentors-details-header .search-container {
  display: inline-flex;
  width: calc(100vw - 483px);
  margin-right: 24px;
}
.mentors-details-header .add-mentor {
  display: inline-flex;
}
td.status .success,td.status .danger{
  box-sizing: border-box;
  border-radius: 8px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  width: 111px;
  display: flex;
  justify-content: center;
  margin: auto;
}
td.status .success {
  color: #00CA72;
  background: rgba(0, 202, 114, 0.1);
  border: 1px solid #B3FFDE;
}

td.status .danger {
  color: #E2445C;
  background: #FFE9E9;
  border: 1px solid #FFD8D8;
}
.action{
  padding: 12px;
  width: 40px;
  height: 40px;
  display: flex;
  margin: auto;
}
</style>
